import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Container, Inner } from './Elements';
import { colors, font } from '../consts/style';

const Wrapper = styled.div`
  background: ${colors.grey};
  color: white;
  padding: 6rem 0;
  margin-top: 6rem;
  p {
    margin: 0;
  }
  .title {
    ${font.h3}
    margin-bottom: 2.4rem;
  }

  .item {
    margin-bottom: 0.8rem;
    span {
      font-weight: bold;
      margin-right: 1.2rem;
    }
  }
`;

export default function ContactSection({ contacts }) {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <p className="title">Get In Touch</p>
          {contacts.map(c => (
            <p className="item" key={c.id}>
              <span>{c.label}</span> {c.emailAddress}
            </p>
          ))}
        </Inner>
      </Container>
    </Wrapper>
  );
}

ContactSection.propTypes = {
  contacts: PropTypes.array.isRequired,
};
